import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket!: Socket;
  private readonly SOCKET_URL = environment.SOCKET_URL;
  private socketResponseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  socketResponse$ = this.socketResponseSubject.asObservable();

  constructor(private http: HttpClient) {
    this.initSocket();
  }

  setQuery(query: any): void {
    this.socket.io.opts.query = query;
  }

  private initSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
    }

    this.socket = io(this.SOCKET_URL, {
      transports: ['websocket', 'polling'],
      withCredentials: true,
      reconnection: true,
    });

    this.socket.on('connect', () => {
      console.log('Connected to socket server');
    });

    this.socket.on('receive_message', (res: any) => {
      console.log('Received message:', res);
      this.socketResponseSubject.next(res);
    });

    this.socket.on('disconnect', (reason: string) => {
      console.log('Disconnected from socket server:', reason);
    });

    this.socket.on('connect_error', (error: any) => {
      console.error('Connection error:', error);
    });

    this.socket.on('error', (error: any) => {
      console.error('Socket error:', error);
    });
  }

  joinRoom(room: string): void {
    if (this.socket) {
      this.socket.emit('join', room);
      console.log(`Joined room ${room}`);
    }
  }

  sendMessage(data: { message: any; room: any; content: any; userId: any; platforme?: any }): void {
    if (!this.socket) {
      console.error('Socket is not initialized.');
      return;
    }

    this.socket.emit('send_message', data);
    console.log('Message sent:', data);
  }
  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
  getMessages(room: string, page: number, size: number): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/messages/room/${room}?page=${page}&size=${size}`);
  }

  saveMessage(messageData: { text: string; room: string }): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/messages`, messageData);
  }
}
