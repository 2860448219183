export const environment = {
  production: false,
  baseUrlImage: 'https://uat.pharma-go.fr/file/..',
  baseUrl: 'https://uat.pharma-go.fr/',
  googleMapsApiKey: 'AIzaSyCRcEr5d8ePuI2b8V3MPFCLSrMDC1hg-z0',
  firebase: {
    apiKey: 'AIzaSyBhE5Yxyu1UIlOZ_zE5V5myQ3SQlsQzmB8',
    authDomain: 'pharmago-c0a58.firebaseapp.com',
    projectId: 'pharmago-c0a58',
    storageBucket: 'pharmago-c0a58.appspot.com',
    messagingSenderId: '392409823744',
    appId: '1:392409823744:web:9241439f50c20a7412f6e5',
    measurementId: 'G-P798YF9VB7',
    vapidKey: 'BArGAWt5xpJy_bVVizRsy2VsH700HlbLzqSdbfXu61vEw5CKeibe9TXbba9HoDApSrMXu8rnE9dEI2UPLJwDAmg',
  },
  SOCKET_URL: 'https://chat.pharma-go.fr',
};
