@if (account?.authorities?.includes('ROLE_USER') || 1) {
  <div>
    <div class="row mt-5 mb-4">
      <div class="col-md-7">
        <h2 class="lead" style="color: #0078c6">Choisir la pharmacie</h2>
      </div>
      <div class="col-md-5 col-sm-12">
        <div class="switch d-flex justify-content-sm-start justify-content-md-end">
          <button class="button" (click)="activateButton('left')" [class.active]="activeButton === 'left'">Sur Liste</button>
          <button class="button" (click)="activateButton('right')" [class.active]="activeButton === 'right'">Sur Carte</button>
        </div>
      </div>
    </div>
    <span *ngIf="!pharmacy.length">
      <lottie-player
        src="../../content/images/noData.json"
        background="transparent"
        speed="1"
        style="width: 300px; height: 250px; margin: auto"
        loop
        autoplay
      ></lottie-player>
    </span>
    <section
      *ngIf="activeButton !== 'right'"
      infiniteScroll
      class="search-results"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="1000"
      (scrolled)="onScroll()"
    >
      <div class="row align-items-start mw-100 w-100">
        <div class="pharmacies-list col-sm-6 col-lg-4 col mb-3" *ngFor="let pharma of pharmacy; let i = index">
          <div>
            <div *ngIf="!contentLoaded" class="card product-card" style="border: 1px solid #e5e7eb; border-radius: 16px; cursor: pointer">
              <ngx-skeleton-loader
                count="1"
                appearance="circle"
                class="prod-img-top"
                [theme]="{ width: '80px', height: '80px', 'border-radius': '10px' }"
              ></ngx-skeleton-loader>
              <div class="card-body" style="display: flex; flex-direction: column">
                <ngx-skeleton-loader count="1" appearance="line" style="width: 60%; height: 20px"></ngx-skeleton-loader>
                <div class="d-flex flex-row mb-3"></div>
                <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px; margin-top: 10px"></ngx-skeleton-loader>
                <ngx-skeleton-loader count="1" appearance="line" style="width: 40%; height: 20px; margin-top: 10px"></ngx-skeleton-loader>
                <div class="d-flex flex-row align-items-center justify-content-between" style="margin-top: 10px">
                  <div class="col-5 d-flex align-items-center flex-column"></div>
                </div>
              </div>
            </div>
            <div
              (click)="NavigateToPharmacy(pharma?.id, pharma?.commercialName, pharma?.longitude, pharma?.latitude)"
              class="card pharmacy-card"
              *ngIf="activeButton === 'left' && contentLoaded"
              style="border: 1px solid #e5e7eb; border-radius: 16px"
            >
              <img *ngIf="pharma.frontPath" [src]="imageUrl + pharma.frontPath + '/large'" class="card-img-top" alt="Image" />
              <img
                *ngIf="!pharma.frontPath"
                src="https://imagedelivery.net/z1UHS7Z96nNnNVE8sIpBxg/3dc6e2c9-0d8d-454e-c275-8e2d75018f00/Cpub"
                class="card-img-top"
                alt="Image"
              />
              <div class="card-body" style="display: flex; flex-direction: column; background-color: #fbfbfb">
                <p [style.color]="getStatusColor(pharma.status)">
                  <img
                    *ngIf="pharma.status === 'open'"
                    style="margin-top: -3px"
                    src="../../content/images/icons/Ouverte.svg"
                    alt="Ouverte"
                  />
                  <img
                    *ngIf="pharma.status === 'closed'"
                    style="margin-top: -3px"
                    src="../../content/images/icons/Fermée.svg"
                    alt="Fermée"
                  />
                  <img
                    *ngIf="pharma.status === 'open_soon' || pharma.status === 'close_soon'"
                    style="margin-top: -3px"
                    src="../../content/images/icons/open-after.svg"
                    alt="Bientôt"
                  />
                  {{ getStatusMessage(pharma.status) }}
                </p>

                <h5 class="card-title text-truncate">
                  {{ pharma?.commercialName }}
                </h5>
                <p class="card-text address">{{ pharma?.address }}</p>
                <a class="btn btn-light d-flex delivery" *ngIf="pharma?.distance">{{ (pharma?.distance / 1000).toFixed(2) }} km</a>
                <a class="btn btn-light d-flex likeBtn" (click)="stopEventPropagation($event); toggleLike(pharma, i)">
                  <img
                    [src]="
                      pharma.liked ? '../../content/images/Icon feather-heart (2).svg' : '../../content/images/Icon feather-heart (1).svg'
                    "
                    alt="Like"
                  />
                </a>
                <a class="btn order" style="border: 2px solid #0078c6; border-radius: 20px; color: #0078c6">Passer commande</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div *ngIf="activeButton === 'right' && showMap && pharmacy.length > 0" class="map-container-home">
      <button class="search-button" *ngIf="showSearchButton" (click)="searchInCurrentArea()">Rechercher sur cette zone</button>
      <google-map #mapComponent height="400px" width="100%" [center]="center" [zoom]="zoom" (mapDragend)="logCenter()">
        <map-marker
          style="cursor: pointer"
          *ngFor="let pharma of pharmacy"
          [position]="{ lat: pharma.latitude, lng: pharma.longitude }"
          [options]="options"
          #somemarker="mapMarker"
          (mapClick)="openInfoWindow(somemarker, pharma)"
        >
          <map-info-window #infoWindow>
            <div
              class="info-body"
              tabindex="0"
              ngbAutofocus
              (click)="
                NavigateToPharmacy(
                  selectedPharmacy?.id,
                  selectedPharmacy?.commercialName,
                  selectedPharmacy?.longitude,
                  selectedPharmacy?.latitude
                )
              "
            >
              <div>
                <img
                  *ngIf="selectedPharmacy?.frontPath"
                  [src]="imageUrl + selectedPharmacy.frontPath + '/small'"
                  class="card-img-top"
                  alt="Image"
                  style="height: 100%"
                />
              </div>

              <div *ngIf="selectedPharmacy">
                <h3
                  class="namePharmacy"
                  style="cursor: pointer; font-size: 16px; color: #0078c6"
                  (click)="
                    NavigateToPharmacy(
                      selectedPharmacy?.id,
                      selectedPharmacy?.commercialName,
                      selectedPharmacy?.longitude,
                      selectedPharmacy?.latitude
                    )
                  "
                >
                  {{ selectedPharmacy.commercialName }}
                </h3>
                <p class="phoneNumber" style="color: #83b011">
                  Phone Number: <span style="color: #141414">{{ selectedPharmacy.phoneNumber }}</span>
                </p>
                <p class="address" style="color: #83b011">
                  Address: <span style="color: #141414">{{ selectedPharmacy.address }}</span>
                </p>
              </div>
            </div>
          </map-info-window>
        </map-marker>
        <!--
        <map-marker *ngIf="markerPosition" [position]="markerPosition" [options]="markerOptions" [draggable]="true"></map-marker>
        -->
      </google-map>
    </div>
  </div>
}

<!-- map-modal.component.html -->
<!-- location-modal.component.html -->

<ng-template #modalAvis let-modalAvis>
  <div class="modal-avis">
    <div class="modal-header">
      <div></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalAvis.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="">
      <div class="avis-header">
        <img src="/content/images/icons/avis-header.png" alt="" />
      </div>
      <div class="title">
        Donner votre Avis sur la commande
        <span class="command-id">ID: 0714528/24 </span>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="row">
        <div class="col-sm-12">
          <div class="rating-line">
            <label for="">Service de la pharmacie</label>
            <ul class="rating" data-mdb-rating-init="" tabindex="0" data-mdb-rating-initialized="true" style="outline: none">
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Bad"
                  data-mdb-original-title="Bad"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Poor"
                  data-mdb-original-title="Poor"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="OK"
                  data-mdb-original-title="OK"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Good"
                  data-mdb-original-title="Good"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star"
                  data-mdb-toggle="tooltip"
                  aria-label="Excellent"
                  data-mdb-original-title="Excellent"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
            </ul>
          </div>
          <div class="rating-line">
            <label for="">Service de livraison</label>
            <ul class="rating" data-mdb-rating-init="" tabindex="0" data-mdb-rating-initialized="true" style="outline: none">
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Bad"
                  data-mdb-original-title="Bad"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Poor"
                  data-mdb-original-title="Poor"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="OK"
                  data-mdb-original-title="OK"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Good"
                  data-mdb-original-title="Good"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star"
                  data-mdb-toggle="tooltip"
                  aria-label="Excellent"
                  data-mdb-original-title="Excellent"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
            </ul>
          </div>
          <div class="rating-line">
            <label for="">Service de la Pharmago</label>
            <ul class="rating" data-mdb-rating-init="" tabindex="0" data-mdb-rating-initialized="true" style="outline: none">
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Bad"
                  data-mdb-original-title="Bad"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Poor"
                  data-mdb-original-title="Poor"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="OK"
                  data-mdb-original-title="OK"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star yellow-star"
                  data-mdb-toggle="tooltip"
                  aria-label="Good"
                  data-mdb-original-title="Good"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
              <li>
                <i
                  class="star"
                  data-mdb-toggle="tooltip"
                  aria-label="Excellent"
                  data-mdb-original-title="Excellent"
                  data-mdb-tooltip-initialized="true"
                ></i>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <button class="submit" type="button" (click)="modalAvis.dismiss('Cross click')">Confirmer</button>
    </div>
  </div>
</ng-template>
<ng-template #modalDetailsPharmacy let-modalDetailsPharmacy>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-start">
        <span class="title">Pharmacy Details</span>
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalDetailsPharmacy.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <h3
        class="namePharmacy"
        style="cursor: pointer; font-size: 22px"
        (click)="
          NavigateToPharmacy(
            selectedPharmacy?.id,
            selectedPharmacy?.commercialName,
            selectedPharmacy?.longitude,
            selectedPharmacy?.latitude
          );
          modalDetailsPharmacy.dismiss('Cross click')
        "
      >
        {{ selectedPharmacy.commercialName }}
      </h3>
      <div *ngIf="selectedPharmacy">
        <img
          *ngIf="selectedPharmacy?.frontPath"
          [src]="imageUrl + selectedPharmacy.frontPath + '/small'"
          class="card-img-top"
          alt="Image"
          style="margin-bottom: 20px"
        />
        <p class="phoneNumber" style="color: #83b011">
          Phone Number: <span>{{ selectedPharmacy.phoneNumber }}</span>
        </p>
        <p class="address" style="color: #83b011">
          Address: <span>{{ selectedPharmacy.address }}</span>
        </p>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #modalgeo let-modalgeo>
  <div class="modal-header">
    <div class="col-11 d-flex justify-content-center">
      <img src="../../content/images/map-modal-img.png" alt="" />
    </div>
    <div class="col-1 d-flex align-items-end flex-column">
      <button type="button" class="close" (click)="modalgeo.dismiss('Cross click')" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="d-flex flex-column align-items-center" [formGroup]="adresseForm">
      <h3>Ajouter une adresse de livraison</h3>
      <span class="mb-4">
        Pour améliorer votre expérience client et réduire le temps de livraison, veuillez indiquer l'adresse de livraison
      </span>
      <div class="col-12">
        <auto-complete (placeSelected)="onPlaceSelected($event)" [optionalInput]="address" formControlName="address"></auto-complete>
      </div>
      <div class="d-flex justify-content-between w-100">
        <div>
          <label for="">Etage / Appartement</label>
          <input
            type="text"
            formControlName="appartmentNumber"
            placeholder="Etage / Appartement"
            formControlName="appartmentNumber"
            class="form-control mb-3"
          />
        </div>
        <div>
          <label for="">Nom adresse</label>
          <input type="text" formControlName="name" placeholder="Ex: Maison / Bureau" formControlName="name" class="form-control mb-3" />
        </div>
      </div>
      <div class="d-flex justify-content-between w-100 down-input">
        <span class="place" (click)="useCurrentLocation(); modalgeo.dismiss('Cross click')" style="cursor: pointer"
          >Utiliser ma position</span
        >
        <span class="position" (click)="openMapInHome(); modalgeo.dismiss('Cross click')" style="cursor: pointer"
          >Définir sur la carte</span
        >
      </div>
      <button class="btn btn-success mt-3 saveAdresse" (click)="addAdressToclient(); modalgeo.dismiss('Cross click')">ENREGISTRER</button>
      <div class="col-12 d-flex flex-column align-items-start down-input"></div>
    </div>
  </div>
</ng-template>
<ng-template #modalgeoAdresse let-modalgeoAdresse>
  <div class="modal-product">
    <div class="modal-header modalgeoAdresse">
      <div class="col-11 d-flex justify-content-center">
        <img src="../../content/images/map-modal-img.png" alt="" />
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalgeoAdresse.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex flex-column align-items-center">
        <div class="col-12">
          <!-- Maison Address -->
          <div class="d-flex align-items-center justify-content-between cart-item">
            <div class="address-checkbox">
              <input type="radio" name="address" value="Maison" id="maison" />
            </div>
            <div class="address-info">
              <label for="maison" class="address-label">Maison</label>
              <div class="address-detail">10 Bd de Sébastopol, étage 3, Appartement 10, 75004 Paris, France</div>
            </div>
            <div class="address-actions">
              <div class="btn-wrap">
                <button class="btn-command btn-edit"></button>
                <button class="btn-command btn-delete"></button>
              </div>
            </div>
          </div>
          <hr style="visibility: hidden" />
          <!-- Bureau Address -->
          <div class="d-flex align-items-center justify-content-between cart-item">
            <div class="address-checkbox">
              <input type="radio" name="address" value="Bureau" id="bureau" />
            </div>
            <div class="address-info">
              <label for="bureau" class="address-label">Bureau</label>
              <div class="address-detail">10 Bd de Sébastopol, étage 3, Appartement 10, 75004 Paris, France</div>
            </div>
            <div class="address-actions">
              <div class="btn-wrap">
                <button class="btn-command btn-edit"></button>
                <button class="btn-command btn-delete"></button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 d-flex flex-column align-items-center mt-3 down-input">
          <span class="position" (click)="openModal(modalgeoTow); modalgeoAdresse.close()" style="cursor: pointer"
            >Ajouter une nouvelle adresse de livraison</span
          >
        </div>
        <button class="btn btn-success mt-3 saveAdresse">ENREGISTRER</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalgeoTow let-modalgeoTow>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center">
        <img src="../../content/images/map-modal-img.png" alt="" />
      </div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalgeoTow.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex flex-column align-items-center">
        <h3>Modifier adresse de livraison</h3>
        <div class="col-12">
          <auto-complete (placeSelected)="onPlaceSelected($event); modalgeoTow.dismiss('Cross click')"></auto-complete>
        </div>
        <div class="d-flex justify-content-between w-100">
          <div>
            <label for="">Etage / Appartement</label>
            <input type="text" placeholder="Etage / Appartement" class="form-control mb-3" />
          </div>
          <div>
            <label for="">Nom adresse</label>
            <input type="text" placeholder="Ex: Maison / Bureau" class="form-control mb-3" />
          </div>
        </div>

        <div class="d-flex justify-content-between w-100 down-input">
          <span class="place" (click)="useCurrentLocation()" style="cursor: pointer">Utiliser ma position</span>
          <span class="position" (click)="openMapInHome(); modalgeoTow.dismiss('Cross click')" style="cursor: pointer"
            >Définir sur la carte</span
          >
        </div>
        <button class="btn btn-success mt-3 saveAdresse">ENREGISTRER</button>
        <div class="col-12 d-flex flex-column align-items-start down-input"></div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #modalMaps let-modalMaps>
  <div class="modal-product">
    <div class="modal-header">
      <div class="col-11 d-flex justify-content-center"></div>
      <div class="col-1 d-flex align-items-end flex-column">
        <button type="button" class="close" (click)="modalMaps.dismiss('Cross click')" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body" tabindex="0" ngbAutofocus>
      <div class="d-flex flex-column align-items-center">
        <h3>Choisir l'emplacement sur la carte</h3>
        <google-map #mapComponent height="400px" [center]="center" [zoom]="zoom">
          <map-marker
            (mapDragend)="logCenter2($event)"
            [position]="markerPosition"
            [options]="markerOptions"
            [draggable]="true"
          ></map-marker>
        </google-map>
        <div class="d-flex justify-content-between w-100 map-container"></div>

        <div class="d-flex justify-content-between w-100 down-input"></div>
        <button class="btn btn-success mt-3 closeAdresse" (click)="modalMaps.dismiss('Cross click')">ANNULER</button>
        <button class="btn btn-success mt-3 saveAdresse" (click)="reOpenAddressModal()">VALIDER</button>
        <div class="col-12 d-flex flex-column align-items-start down-input"></div>
      </div>
    </div>
  </div>
</ng-template>
