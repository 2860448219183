import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '../core/auth/account.service';
import { environment } from '../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  private cartItemsSubject = new BehaviorSubject<any[]>([]);
  cartItems$ = this.cartItemsSubject.asObservable();
  private cartItemsCountSubject = new BehaviorSubject<number>(0);
  cartItemsCount$ = this.cartItemsCountSubject.asObservable();
  private totalItemsPrice = new BehaviorSubject<number>(
    isNaN(Number(this.cookieService.get('totalPrice'))) ? 0 : Number(this.cookieService.get('totalPrice')),
  );

  private fileUrlsSubject = new BehaviorSubject<any>({ prescription: [], vital_card: [], mutual_card: [] });
  fileUrls$ = this.fileUrlsSubject.asObservable();

  totalPrice$ = this.totalItemsPrice.asObservable();
  private purchaseIdSubject = new BehaviorSubject<number | null>(
    this.cookieService.get('purchaseId') ? Number(this.cookieService.get('purchaseId')) : null,
  );
  purchaseId$ = this.purchaseIdSubject.asObservable();
  account: any | null = null;

  constructor(
    private http: HttpClient,
    private accountService: AccountService,
    private cookieService: CookieService,
  ) {
    this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      this.loadFromCookies();
    });
  }
  incrementCartItemCount(): void {
    let currentCount = this.cartItemsCountSubject.getValue();
    this.cartItemsCountSubject.next(currentCount + 1);
  }
  findOneProduct(id: any): Observable<any> {
    return this.http.get<any>(`${environment.baseUrl}/stock-items/${id}`);
  }

  getCartItems(): any[] {
    const cartItemsCookieName = this.account ? `cartItems-${this.account.id}` : 'cartItems';
    const storedCartItems = this.cookieService.get(cartItemsCookieName);
    try {
      return storedCartItems ? JSON.parse(storedCartItems) : [];
    } catch (e) {
      console.error('Error parsing cart items from cookies', e);
      return [];
    }
  }

  loadFromCookies() {
    const storedCartItems = this.getCartItems();
    if (storedCartItems && storedCartItems.length > 0) {
      this.cartItemsSubject.next(storedCartItems);
    }
  }

  checkSamePharmacy(newProduct: any): boolean {
    const actualPharmacyId = Number(this.cookieService.get('actualPharmacy'));
    if (!actualPharmacyId) {
      return true;
    } else {
      return newProduct.pharmacy.id === actualPharmacyId;
    }
  }

  getCookie(name: string): string {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'));
    if (matches) {
      try {
        return decodeURIComponent(matches[1]);
      } catch (e) {
        console.error('Error decoding cookie', e);
        return '';
      }
    }
    return '';
  }

  setCookie(name: string, value: string, days: number): void {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + encodeURIComponent(value) + expires + '; path=/';
  }
  removePrescriptionFromCookies(): void {
    if (this.account) {
      const cartCookieKey = `cartItems-${this.account.id}`;
      const cartItemsJson = this.getCookie(cartCookieKey);

      if (cartItemsJson) {
        const cartItems = JSON.parse(cartItemsJson);

        // Filter out the prescription item
        const updatedCartItems = cartItems.filter(item => !item.isPrescription);

        // Save the updated cart back to cookies
        this.setCookie(cartCookieKey, JSON.stringify(updatedCartItems), 7);
        console.log('Prescription removed from cookies');
        this.cartItemsSubject.next([]);
        this.purchaseIdSubject.next(null);
      } else {
        console.warn('No cart items found in cookies');
      }
    } else {
      console.warn('Account is not available. Cannot remove prescription from cookies');
    }
  }
  /*  setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  } */
  addToCart(product: any): void {
    this.setPharmacy(product.pharmacy.id, product.pharmacy.legalName, product.pharmacy.address);

    const currentCart = this.cartItemsSubject.getValue();
    const index = currentCart.findIndex(item => item.id === product.id);
    if (index !== -1) {
      currentCart[index].quantity += 1;
    } else {
      currentCart.push({
        id: product.id,
        quantity: 1,
        productImage: product.product.productImage,
        title: product.product.title,
        // description: product.product.description,
        price: product.price,
      });
    }
    this.cartItemsSubject.next([...currentCart]);
    this.saveCartItems([...currentCart]);
  }
  addPrescriptionToCart(): void {
    const currentCart = this.cartItemsSubject.getValue();

    // Vérifier si une ordonnance a déjà été ajoutée
    const prescriptionIndex = currentCart.findIndex(item => item.isPrescription);

    if (prescriptionIndex === -1) {
      // Ajouter une ordonnance vide au panier
      currentCart.push({
        id: null, // ID nul ou une valeur spécifique pour identifier l'ordonnance
        quantity: 1,
        productImage: null, // Pas d'image
        title: 'Ordonnance',
        // description: '',
        price: 0, // Pas de prix
        isPrescription: true, // Attribut pour identifier l'ordonnance
      });

      this.cartItemsSubject.next([...currentCart]);
      this.saveCartItems([...currentCart]);
    }
  }

  async addToCartWithASpecificNumber(product: any, value: number) {
    this.setPharmacy(product.pharmacy.id, product.pharmacy.legalName, product.pharmacy.address);
    const currentCart = this.cartItemsSubject.getValue();
    const index = currentCart.findIndex(item => item.id === product.id);

    if (index !== -1) {
      currentCart[index].quantity = value;
    } else {
      currentCart.push({
        id: product.id,
        quantity: value,
        productImage: product.product.productImage,
        title: product.product.title,
        // description: product.product.description,
        price: product.price,
      });
    }

    this.cartItemsSubject.next([...currentCart]);
    await this.saveCartItems([...currentCart]);
  }

  private updateCartItemsAccountId(accountId: string): void {
    const currentCart = this.cartItemsSubject.getValue();
    const updatedCart = currentCart.map(item => ({ ...item, accountId }));
    this.cartItemsSubject.next(updatedCart);
    this.saveCartItems(updatedCart);
  }

  saveCartItems(cartItems: any[]): void {
    if (this.account) {
      this.setCookie(`cartItems-${this.account.id}`, JSON.stringify(cartItems), 7);
    } else {
      console.warn('Account is not available. Cart items cannot be saved.');
    }
  }

  setPharmacy(id: number, pharmacyName: string, address: string) {
    const currentCart = this.cartItemsSubject.getValue();
    if (!currentCart.length) {
      this.setCookie('actualPharmacy', id.toString(), 7);
      this.setCookie('pharmacyName', pharmacyName, 7);
      this.setCookie('pharmacyAddress', address, 7);
    }
  }

  clearCartItems(): void {
    this.cartItemsSubject.next([]);
    this.purchaseIdSubject.next(null);

    if (this.account) {
      this.setCookie(`cartItems-${this.account.id}`, '', -1);
    }

    this.cookieService.delete('actualPharmacy');
    this.cookieService.delete('purchaseId');
    this.cookieService.delete('numCommande');

    this.setCookie('purchaseId', '', -1);
    setTimeout(() => {
      console.log('Vérification après suppression du panier : ', this.getCartItems());
    }, 100);
  }
  // clearCartItems(): void {
  //   console.log('Clearing cart items...');

  //   this.cartItemsSubject.next([]);
  //   this.purchaseIdSubject.next(null);

  //   const cartItemsCookieName = this.account ? `cartItems-${this.account.id}` : 'cartItems';
  //   this.cookieService.delete(cartItemsCookieName);
  //   console.log(`Cookie ${cartItemsCookieName} supprimé`);

  //   this.cookieService.delete('actualPharmacy');
  //   console.log('Cookie actualPharmacy supprimé');

  //   this.cookieService.delete('purchaseId');
  //   console.log('Cookie purchaseId supprimé');

  //   this.cookieService.delete('numCommande');
  //   console.log('Cookie numCommande supprimé');
  // }

  getCurrent(product: any): number {
    const currentCart = this.cartItemsSubject.getValue();
    const index = currentCart.findIndex(item => item.id === product.id);

    if (index !== -1) {
      return currentCart[index].quantity;
    } else {
      return 1;
    }
  }

  increaseQuantity(productId: any) {
    const currentCart = this.cartItemsSubject.getValue();
    const index = currentCart.findIndex(item => item.id === productId);
    if (index !== -1) {
      currentCart[index].quantity += 1;
      this.cartItemsSubject.next([...currentCart]);
      this.saveCartItems([...currentCart]);
    }
  }

  deleteFromCart(id: any) {
    const currentCart = this.cartItemsSubject.getValue();
    const index = currentCart.findIndex(item => item.id === id);

    if (index !== -1) {
      currentCart.splice(index, 1);
      this.cartItemsSubject.next([...currentCart]);
      this.purchaseIdSubject.next(null);
      this.saveCartItems([...currentCart]);
    }
  }

  clientConfirmOrder(payload: any): Observable<any> {
    const headers = new HttpHeaders({
      'X-Client-Type': 'web',
    });

    return this.http.post(`${environment.baseUrl}api/purchases/clientConfirm`, payload, { headers });
  }

  initiatePaymentProxy(purchaseId: number, headers: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/payments/proxy/${purchaseId}`, {}, { headers });
  }

  decreaseQuantity(productId: any) {
    const currentCart = this.cartItemsSubject.getValue();
    const index = currentCart.findIndex(item => item.id === productId);
    if (index !== -1) {
      if (currentCart[index].quantity > 1) {
        currentCart[index].quantity -= 1;
      } else {
        currentCart.splice(index, 1);
      }
      this.cartItemsSubject.next([...currentCart]);
      this.saveCartItems([...currentCart]);
    }
  }

  setTotalPrice(total: number) {
    this.totalItemsPrice.next(total);
    this.cookieService.set('totalPrice', total.toString());
  }

  deleteTotalPrice() {
    this.cookieService.delete('totalPrice');
    this.totalItemsPrice.next(0);
  }

  getTotalPrice() {
    return this.totalItemsPrice.asObservable();
  }

  setPurchaseId(purchaseId: number) {
    this.purchaseIdSubject.next(purchaseId);
    this.setCookie('purchaseId', purchaseId.toString(), 7);
  }

  setNumCommande(numCommande: any) {
    this.setCookie('numCommande', numCommande, 7);
  }

  getNumCommande() {
    return this.cookieService.get('numCommande');
  }

  getPurchaseId() {
    return Number(this.cookieService.get('purchaseId'));
  }

  getPharmacy() {
    return Number(this.cookieService.get('actualPharmacy'));
  }

  getPharmacyName() {
    return this.cookieService.get('pharmacyName');
  }

  getPharmacyAddress() {
    return this.cookieService.get('pharmacyAddress');
  }

  PurchasesFees(data: any): Observable<any> {
    return this.http.post(`${environment.baseUrl}api/delivery/estimate?serviceName=deliverygoService`, data);
  }
  getPlateformFee(): Observable<any> {
    return this.http.get(`${environment.baseUrl}api/fees/plateform-fee`);
  }
  getSelectedAddressCoordinates(): { lat: number; lng: number } | null {
    const storedCoords = localStorage.getItem('selectedAddressCoords');
    return storedCoords ? JSON.parse(storedCoords) : null;
  }

  updateCartItems(items: any[]) {
    this.cartItemsSubject.next(items);
  }

  updateFileUrls(fileUrls: any) {
    this.fileUrlsSubject.next(fileUrls);
  }
}
